<template>
  <v-container class="task-modify" fluid>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <entity-modify-header class="mb-10" :title="title" />
      <form-builder v-if="!isLoading" :schema="$options.schema" :initial-data="initialData" @submit="submit">
        <template #footer="{ valid }">
          <v-row class="mt-10">
            <v-col>
              <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn type="submit" color="primary" block :disabled="!valid" :loading="isUpload">
                {{ submitButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </template>
  </v-container>
</template>

<script>
// Models
import { schema } from '@/schemas/taskRecurrence.schema';

// Utils
import { createModelData } from '@/schemas/createModelData';
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';

// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import BaseLoader from '@/components/BaseLoader.vue';

// Constants
import { TASKS_RECURRENCE, TASKS_RECURRENCE_DETAILED } from '@/constants/routes';
import { CREATE_RULE_TASK, EDIT_RULE_TASK } from '@/constants/analyticsActions';

// Services
import issuesService from '@/services/issues';
import mediaService from '@/services/media';

import notificationService from '@/services/notification';
import analyticsService from '@/services/analytics';

export default {
  name: 'TaskRecurrenceModify',

  components: { FormBuilder, EntityModifyHeader, BaseLoader },
  props: {
    isEdit: { type: Boolean, default: false },
  },

  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      initialData: createModelData(schema),
      isUpload: false,
    };
  },

  computed: {
    title() {
      return this.isEdit ? this.$t('task.edit_recurrence_task') : this.$t('task.add_recurrence_task');
    },
    submitButtonText() {
      return this.isEdit ? this.$t('button.edit_task') : this.$t('button.add_task');
    },
    backRoute() {
      return this.$route.params.prevPage || { name: TASKS_RECURRENCE };
    },
  },

  mounted() {
    if (this.isEdit) {
      this.initData();
    }
  },

  methods: {
    async initData() {
      this.isLoading = true;
      try {
        const issue = await issuesService.getRecurrenceIssueById(this.id);

        this.initialData = {
          ...issue,
          client: issue.client?.id ? issue.client : null,
          rolesPending: {
            all: false,
            include: issue.roles.pending ? [...issue.roles.pending] : [],
            exclude: [],
          },
          rolesInProgress: {
            all: false,
            include: issue.roles.inProgress ? [...issue.roles.inProgress] : [],
            exclude: [],
          },
          rolesCompleted: {
            all: false,
            include: issue.roles.completed ? [...issue.roles.completed] : [],
            exclude: [],
          },
          paymentAmount: 0,
          // paymentAmount: issue.paymentAmount.value,
          media: await this.formatMedia(issue.media),
        };
      } finally {
        this.isLoading = false;
      }
    },
    async formatMedia(media) {
      const newMedia = await Promise.all(
        media.map(async file => {
          const newFile = { ...file };
          newFile.name = decodeURIComponent(newFile.url)
            .split('/')
            .pop();

          const { url, blob } = await this.getPrivateMediaObject(newFile.url);

          newFile.url = url;
          newFile.type = blob.type;
          newFile.size = blob.size;
          return newFile;
        })
      );
      return newMedia;
    },
    getPrivateMediaObject(url) {
      return mediaService.getPrivateMediaObject(getBaseUrlWithoutApi() + url);
    },
    async submit(data) {
      if (this.isUpload) {
        return;
      }
      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }
      const bodyRequest = this.createBodyRequest(data);

      this.isUpload = true;

      const saveTask = this.isEdit ? issuesService.updateRecurrenceIssueById : issuesService.createRecurrenceIssue;
      try {
        const task = await saveTask(bodyRequest, this.id);
        analyticsService.track(this.isEdit ? EDIT_RULE_TASK : CREATE_RULE_TASK);
        notificationService.success(this.isEdit ? this.$t('task.edited') : this.$t('task.created'), 2000);
        this.$router.push({ name: TASKS_RECURRENCE_DETAILED, params: { id: task.id || this.id } });
      } catch (error) {
        this.$options.notificationItem = notificationService.error(
          `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
        );
      } finally {
        this.isUpload = false;
      }
    },
    createBodyRequest(data) {
      const bodyRequest = {
        ...data,
        recurrencePeriod: data.recurrencePeriod.value,
        serviceType: data.serviceType.value,
        description: data.description,
        client: data.client?.id || null,
        unit: data.room?.id || data.unit.id,
        isInformClientViaPush: data.isInformClientViaPush,
        roles: {
          pending: [...data.rolesPending.include.map(role => role.id)],
          inProgress: [...data.rolesInProgress.include.map(role => role.id)],
          completed: [...data.rolesCompleted.include.map(role => role.id)],
        },
        isPaymentRequired: false,
        paymentAmount: 0,
        paymentContractor: undefined,
        media: data.media,
      };

      return bodyRequest;
    },
  },

  notificationItem: null,
  schema,
};
</script>

<style lang="scss">
.task-modify {
  max-width: 548px !important;
}
</style>
